import { Layout, Spin } from "antd"
import dynamic from "next/dynamic"
import Router from "next/router"
import React, { useContext, useEffect } from "react"
import styled from "styled-components"

import { AuthContext, getUserMetadata } from "./utils/AuthContext"

const { Sider, Content } = Layout
const SideNav = dynamic(
  () => import("./components/organisms/SideNav").then((mod) => mod.SideNav),
  { ssr: false }
)

const Aside = styled(Sider)`
  flex: 0 0 255px !important;
  max-width: 255px !important;
`

const LayoutWrapper = styled.div`
  max-width: calc(100% - 25px);
  min-width: calc(100% - 255px);
  & .ant-layout-header {
    height: 64px;
    padding: 0 50px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 64px;
    background: #ffffff;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  }
  & .ant-layout-content {
    min-height: 92vh;
    padding-bottom: 0;
    background-color: #f3f4f6 !important;
  }
  & .ant-layout-footer {
    padding-top: 0;
  }
`

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 40px;
`

const PrivateRoute = (AuthComponent) => {
  function PrivateComponent({ children }) {
    const { state, dispatch, value, agentInfo } = useContext(AuthContext)
    const { user } = state || { user: null }
    const { loading, result } = value || { loading: false, result: null }
    const isAuthenticated = result != null
    const restrictedRoutes = agentInfo?.restrictions || []

    useEffect(() => {
      const { pathname } = Router
      if (!loading) {
        if (isAuthenticated && !user) {
          ;(async () => {
            const { uid, role } = await getUserMetadata(result)
            dispatch({
              type: "SET_USER",
              payload: { result, uid, role },
            })
          })()
        }
        if (!isAuthenticated) {
          Router.push("/login")
          return
        }

        if (restrictedRoutes.includes(pathname)) {
          Router.push("/chat")
          return
        }
        if (isAuthenticated && pathname === "/") {
          window.location.href = "/dashboard"
        }
      }
    }, [isAuthenticated, loading, result, agentInfo?.restrictions])

    if (loading) {
      return (
        <LoaderWrapper>
          <Spin size="large" className="loader" />
        </LoaderWrapper>
      )
    }
    return <>{isAuthenticated && <> {children} </>} </>
  }

  return class Higher extends React.Component {
    render() {
      return (
        <PrivateComponent>
          <Layout>
            <Aside trigger={null} collapsed={false}>
              <SideNav />
            </Aside>
            <LayoutWrapper>
              <Content>
                <AuthComponent {...this.props} />
              </Content>
            </LayoutWrapper>
          </Layout>
        </PrivateComponent>
      )
    }
  }
}

export default PrivateRoute
