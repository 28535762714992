import React from "react"
import { LoadingOutlined } from "@ant-design/icons/lib/icons"
import styled from "styled-components"
import PrivateRoute from "../withPrivateRoute"
import dynamic from "next/dynamic"

const Loader = dynamic(
  () => import("@project/shared").then((mod) => mod.Loader),
  { ssr: false }
)
const InitalLoader = styled.div`
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Homepage = () => {
  const antIcon = <LoadingOutlined />

  return (
    <>
      <InitalLoader>
        <Loader indicator={antIcon} size="large" />
      </InitalLoader>
    </>
  )
}

export default PrivateRoute(Homepage)
